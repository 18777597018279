
import { defineComponent, onMounted, reactive, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";

import moment from "moment";
import store from "@/store";

export default defineComponent({
  name: "CZElectricityDemand",

  components: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Electricity Demand", ["Market Insight"]);
    });

    const chartOptions = {
      chart: {
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        labels: {
          datetimeUTC: false,
          formatter: function (val, timestamp) {
            return moment(new Date(timestamp)).format("MMM DD");
          },
          style: {
            colors: "#9899ac",
          },
        },

        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        decimalsInFloat: 0,
        labels: {
          style: {
            colors: "#9899ac",
          },
          offsetX: 0,
        },
        title: {
          text: "£/MWh",
          style: {
            fontSize: "15px",
            fontWeight: 600,
          },
        },
      },
      grid: {
        show: false,
      },
      tooltip: {
        x: {
          format: "dd/MM/yy",
        },
        y: {
          formatter: function (val) {
            return (val / 1000).toFixed(1) + "k";
          },
        },
      },
      legend: {
        position: "top",
        tooltipHoverFormatter: function (seriesName, opts) {
          return (
            seriesName +
            " : <strong>" +
            opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
            "</strong>"
          );
        },
      },
    };

    const series = reactive([{}]);
    watch(
      () => store.getters.layoutConfig("toolbar.filterDate"),
      function () {
        getContent();
      }
    );

    /**
     *@description For getting Electricity Demand report
     */
    async function getContent() {
      //for Removing the old array before storing new values.
      series.splice(0);
      let year = store.getters.layoutConfig("toolbar.filterDate");
      let momentVariable = moment(year).format("YYYY");
      ApiService.get("api/marketinsight/elecdemand/getdemand/" + momentVariable)
        .then(async ({ data }) => {
          const response = data;

          //For getting name values
          const headers = response[0];

          //For removing the first row headers from response so can map the fields
          response.splice(0, 1);
          series.push({
            name: headers.f2,
            data: response.map((a) => [new Date(a.f1), a.f2]),
          });
        })
        .catch();
    }

    getContent();

    return {
      series,
      chartOptions,
    };
  },
});
